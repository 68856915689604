<template>
  <div class="flex align-items-center gap-2">
    <cagnotte title="Trésorerie" color="#6D8E79FF" :value="avance"/>
    <div class="text-2xl font-bold">+</div>
    <cagnotte title="En attente de paiement" color="#DD901DFF" :value="attenteReglement"/>
    <div class="text-2xl font-bold">=</div>
    <cagnotte title="Cagnotte cumul" color="#4C63B5FF" :value="cumulReserve"/>
  </div>
</template>

<script>
import Cagnotte from "@/components/Cagnotte";
export default {
  name: "BlocCagnotte",
  components: {Cagnotte},
  props: {
    independent: {
      type: Object
    },
    customValues: {
      type: Object,
      default: () => {
        return {
          treasury: 0,
          waitingToPay: 0,
          pot: 0
        }
      }
    }
  },
  data () {
    return {
      stopAsync: false,
      avance: 0,
      attenteReglement: 0,
      cumulReserve: 0,
    }
  },
  watch: {
    async independent () {
      await this.getData()
    }
  },
  async created () {
    await this.getData()
  },
  methods: {
    async getData () {
      if (this.independent?.id) {
        const pot = await this.$store.dispatch('independent/getCagnotteByIndependent', this.independent.id)
        this.avance = pot.dispo
        this.attenteReglement = pot.previsio - pot.dispo
        this.cumulReserve = pot.previsio
      } else {
        this.avance = this.customValues?.treasury ?? 0
        this.attenteReglement = this.customValues?.waitingToPay ?? 0
        this.cumulReserve = this.customValues?.pot ?? 0
      }
    }
  }
}
</script>

<style scoped>

</style>
