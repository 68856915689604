<template>
  <div>
    <Dialog v-model:visible="showDialogLocal" :header="headerLabel"
            :style="{width: '70%', transition: 'left 0.5s cubic-bezier(0.51, -0.01, 0, 0.94) 0s', position: 'absolute', left: dialogReview ? '1%' : '15%'}"
            modal draggable class="p-fluid" @hide="dialogReview = false; $emit('close')">
      <template #header>
        <NameHeader :independent="ndf.independent" :backward-button="false" :show-money-account="false"/>
      </template>
      <div v-if="ndf?.type === constants.NDF_TYPE.NDFR || ndf.mission" class="flex mt-5 mb-3 align-items-center" style="gap: 3rem;">
        <div class="flex align-items-center">
          <label for="refacturable" class="mr-5 font-bold">Refacturable client</label>
          <div>
            <Checkbox id="refacturable" :disabled="true" :binary="true" v-model="refacturable"/>
          </div>
        </div>
        <div v-if="refacturable && (ndf?.type === constants.NDF_TYPE.NDFR || ndf.mission)" class="flex align-items-center" style="gap: 1rem;">
          <label for="mission">Mission</label>
          <InputText id="mission" :model-value="ndf.mission.clientContract.name" disabled required="true" />
        </div>
        <div class="flex align-items-center" style="gap: 1rem;">
          <label for="libelle">Libellé</label>
          <InputText id="libelle" v-model="libelle" disabled />
        </div>
        <div class="flex align-items-center" style="gap: 1rem;">
          <label for="date">Date</label>
          <Calendar id="date" :disabled="ndf?.adminStatus !== constants.NDF_ADMIN_STATUS.VALIDATE"
                    :showIcon="true" date-format="mm/yy" view="month" v-model="date" required="true" />
        </div>
      </div>
      <!--      <div class="flex justify-content-center mt-5 mb-5">-->
      <!--        <FileUpload mode="basic" choose-label="Nouvelle dépense" auto custom-upload @uploader="getocr" accept="image/*" :maxFileSize="10000000" />-->
      <!--      </div>-->

      <div v-if="depenses.length">
        <TreeTable :value="depenses" :expanded-keys="expandedKeys">
          <template #header>
            Dépenses
          </template>
          <Column class="flex align-items-center" field="nature" header="Nature" :expander="true">
            <template #body="value">
              <div v-if="value.node.key.toString().indexOf('-') === -1 && ndf?.adminStatus !== constants.NDF_ADMIN_STATUS.VALIDATE && ndf?.adminStatus !== constants.NDF_ADMIN_STATUS.REFUND" class="flex">
                <Dropdown style="max-width: 8vw" :disabled="ndf?.adminStatus !== constants.NDF_ADMIN_STATUS.VALIDATE" v-model="value.node.nature" :options="natures" option-label="label" option-group-label="label" option-group-children="items"></Dropdown>
              </div>
              <div v-else style="max-width: 8vw">
                {{ value.node.nature.label }}
              </div>
            </template>
          </Column>
          <Column field="amountexvat" header="Prix HT">
            <template #body="value">
              <InputNumber
                v-model="value.node.data.amountexvat"
                locale="fr-FR"
                mode="currency"
                currency="EUR"
                :disabled="(!value.node.editable && value.node.key.toString().indexOf('-') === -1) || (ndf.adminStatus !== constants.NDF_ADMIN_STATUS.VALIDATE)"
                @input="(event) => updateSomme('amountexvat', value.node.key, event)"
              />
            </template>
          </Column>
          <Column field="vatamount" header="TVA">
            <template #body="value">
              <InputNumber
                  v-model="value.node.data.vatamount"
                  locale="fr-FR"
                  mode="currency"
                  currency="EUR"
                  :disabled="(!value.node.editable && value.node.key.toString().indexOf('-') === -1) || (ndf.adminStatus !== constants.NDF_ADMIN_STATUS.VALIDATE)"
                  @input="(event) => updateSomme('vatamount', value.node.key, event)"
              />
            </template>
          </Column>
          <Column field="amount" header="Prix TTC">
            <template #body="value">
              <InputNumber disabled :model-value="value.node.data.amount" locale="fr-FR" mode="currency" currency="EUR" />
            </template>
          </Column>
          <Column field="date" header="Date">
            <template #body="value">
              <div class="flex" v-if="value.node.key.toString().indexOf('-') === -1">
                <Calendar :disabled="ndf?.adminStatus !== constants.NDF_ADMIN_STATUS.VALIDATE" :showIcon="true" date-format="dd/mm/yy" v-model="value.node.data.date" required="true" />
              </div>
            </template>
          </Column>
          <Column v-if="hasAccess($route.name, true)" field="file" header="Actions">
            <template #body="value">
              <div class="flex" v-if="value.node.key.toString().indexOf('-') === -1">
                <Button v-if="value.node.justificatif" v-tooltip.top="'Voir le justificatif'"
                        type="button" icon="pi pi-eye" class="p-button-success" style="margin-right: .5em"
                        @click="reviewDepense(value.node.justificatif)"/>
                <Button v-if="ndf?.adminStatus === constants.NDF_ADMIN_STATUS.VALIDATE"
                        v-tooltip.top="value.node.justificatif ? 'Remplacer le justificatif' : 'Ajouter un justificatif'"
                        type="button" icon="pi pi-upload" class="p-button-info" style="margin-right: .5em"
                        @click="$refs['upload' + value.node.id].choose()"/>
                <FileUpload :ref="'upload' + value.node.id" style="display: none" mode="basic" auto custom-upload
                            accept="image/png, image/jpeg, application/pdf"
                            @uploader="(file) => { onUploadJustificatif(file, value.node) }" :maxFileSize="10000000" />
                <Button v-if="ndf?.adminStatus === constants.NDF_ADMIN_STATUS.VALIDATE" v-tooltip.top="'Supprimer la dépense'"
                        type="button" icon="pi pi-times" class="p-button-danger mr-2" @click="removeDepense(value)"/>
                <Button v-tooltip.top="'Commentaires'" type="button" icon="pi pi-info" class="p-button-success"
                        @click="clickCommentairesDepense(value.node)"/>
              </div>
            </template>
          </Column>
        </TreeTable>
      </div>

      <div v-else-if="ikm.length !== 0">
        <DataTable :value="ikm">
          <template #header>
            IKM
          </template>
          <template #empty>
            {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
          </template>
          <template #loading>
            {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
          </template>
          <Column field="vehicle.name" :header="$t('vehicle').toUpperCase()">
          </Column>
          <Column field="distanceTrajet" :header="$t('distanceTrajet').toUpperCase()">
            <template #body="data">
              <span>{{ data.data.distanceTrajet }} km</span>
            </template>
          </Column>
          <Column field="nombreTrajets" :header="$t('nombreTrajets').toUpperCase()">
          </Column>
          <Column field="distanceTotale" :header="$t('distanceTotale').toUpperCase()">
            <template #body="data">
              <span>{{ data.data.distanceTotale.toFixed(2) }} km</span>
            </template>
          </Column>
          <Column field="price" :header="$t('price').toUpperCase()">
            <template #body="data">
              <InputNumber
                  v-if="data.data.refacturable" v-model="data.data.price"
                  :disabled="(!data?.node?.editable && data?.node?.key.toString().indexOf('-') === -1) || (ndf.adminStatus !== constants.NDF_ADMIN_STATUS.VALIDATE)"
                  currency="EUR" locale="fr-FR" mode="currency" @input="updateTTCs"
              />
              <span v-else>{{ getEuroFormat(data.data.price * 100) }}</span>
            </template>
          </Column>
          <Column field="date" :header="$t('date').toUpperCase()">
            <template #body="{data}">
              <span v-for="(date, index) in data.dates" :key="index"> {{ getDateFormated(date) }}{{ data.dates.length - 1 === index ? '' : ', ' }}</span>
            </template>
          </Column>
          <Column v-if="hasAccess($route.name, true)" field="file" header="Actions">
            <template #body="value">
              <div class="flex">
                <Button type="button" icon="pi pi-eye" class="p-button-success" @click="reviewIkm(value)" style="margin-right: .5em"></Button>
                <Button v-if="ndf?.adminStatus === constants.NDF_ADMIN_STATUS.VALIDATE" type="button" icon="pi pi-times" class="p-button-danger" @click="currentIkm = value; dialogRefusIkm = true"></Button>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>

      <div class="col-12 mt-5" v-if="ikm.length === 0">
        <div class="card widget-boxes p-0 grid grid-nogutter">
          <div class="col-12 md:col-6 lg:col-4 py-2 px-6 border-none md:border-right-1 border-solid">
            <div class="mb-3">
              <Avatar icon="pi pi-money-bill" size="large" shape="circle" class="text-base font-bold"
                      :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
              <span class="text-xl ml-2">Total HT</span>
            </div>
            <span class="block font-bold text-5xl mb-3">{{ getEuroFormat(totalhtComputed * 100) }}</span>
          </div>
          <div class="col-12 md:col-6 lg:col-4 py-2 px-6 border-none md:border-right-1 border-solid">
            <div class="mb-3">
              <Avatar icon="pi pi-money-bill" size="large" shape="circle" class="text-base font-bold"
                      :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
              <span class="text-xl ml-2">TVA</span>
            </div>
            <span class="block font-bold text-5xl mb-3">{{ getEuroFormat(totaltaxesComputed * 100) }}</span>
          </div>
          <div class="col-12 md:col-6 lg:col-4 py-2 px-6 border-none">
            <div class="mb-3">
              <Avatar icon="pi pi-money-bill" size="large" shape="circle" class="text-base font-bold"
                      :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
              <span class="text-xl ml-2">Total TTC</span>
            </div>
            <span class="block font-bold text-5xl mb-3">{{ getEuroFormat(totalttcComputed * 100) }}</span>
          </div>
        </div>
      </div>
      <div class="col-6 mt-5" v-else style="margin: auto;">
        <div class="card widget-boxes p-0 grid grid-nogutter justify-content-center">
          <div class="col-6 md:col-4 py-2 px-6 border-none">
            <div class="mb-3">
              <Avatar icon="pi pi-money-bill" size="large" shape="circle" class="text-base font-bold"
                      :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
              <span class="text-xl ml-2">Prix total</span>
            </div>
            <span class="block font-bold text-5xl mb-3">{{ getEuroFormat(totalpriceComputed * 100) }}</span>
          </div>
        </div>
      </div>
      <template #footer v-if="hasAccess($route.name, true)">
        <Button v-if="ndf?.adminStatus === constants.NDF_ADMIN_STATUS.VALIDATE" label="Refuser la note" icon="pi pi-times" class="p-button-text" @click="dialogRefusNDF = true"/>
        <Button v-if="ndf?.adminStatus === constants.NDF_ADMIN_STATUS.VALIDATE" label="Enregistrer le brouillon" icon="pi pi-save" class="p-button-text" @click="preConfirmSave(SUBMIT_TYPE.SAVE, saveNDF)" />
        <Button v-if="ndf?.adminStatus === constants.NDF_ADMIN_STATUS.VALIDATE" label="Valider la note" icon="pi pi-check" class="p-button-text" @click="preConfirmSave(SUBMIT_TYPE.VALIDATE, validateNDF)" />
      </template>
    </Dialog>
    <Dialog v-model:visible="dialogReview" position="right" :style="{width: '25%'}" dismissable-mask class="p-fluid" @hide="fileId = null">
      <template #header>
        <Button v-if="fileId" icon="pi pi-download" class="p-button-rounded" size="small" @click="downloadFile"/>
        <div>&nbsp;</div>
      </template>
      <iframe v-if="imgSrc.includes('application/pdf')" style="width: 100%; height: 50vh" :src="imgSrc + '#toolbar=0&navpanes=0&scrollbar=0'" />
      <img v-else style="width: 100%" :src="imgSrc" />
    </Dialog>
    <Dialog header="Motif de refus" v-model:visible="dialogRefusDepense" :style="{width: '25%'}" :modal="true">
      <Textarea style="width: 100%; border-radius: 10px" :autoResize="true" rows="3" v-model="motifRefusDepense"></Textarea>
      <template #footer>
        <Button label="Confirmer" icon="pi pi-check" @click="removeDepense" autofocus />
      </template>
    </Dialog>
    <Dialog header="Motif de refus" v-model:visible="dialogRefusIkm" :style="{width: '25%'}" :modal="true">
      <Textarea style="width: 100%; border-radius: 10px" :autoResize="true" rows="3" v-model="motifRefusDepense"></Textarea>
      <template #footer>
        <Button label="Confirmer" icon="pi pi-check" @click="removeIkm" autofocus />
      </template>
    </Dialog>
    <Dialog header="Motif de refus" v-model:visible="dialogRefusNDF" :style="{width: '25%'}" :modal="true">
      <Textarea style="width: 100%; border-radius: 10px" :autoResize="true" rows="3" v-model="motifRefusNDF"></Textarea>
      <template #footer>
        <Button label="Confirmer" icon="pi pi-check" @click="refuserNDF" autofocus />
      </template>
    </Dialog>
    <Dialog v-if="currentDepense" header="Commentaires" v-model:visible="dialogCommentaires" :style="{width: '25%'}" :modal="true">
      <div v-if="currentDepense.independentComment" class="p-float-label mb-4 mt-4">
        <Textarea :disabled="true" id="commentairePorte" style="width: 100%; border-radius: 10px" :autoResize="true" rows="3" v-model="currentDepense.independentComment"></Textarea>
        <label for="commentairePorte">Commentaire porté</label>
      </div>
      <div class="p-float-label">
        <Textarea id="commentaireAdmin" style="width: 100%; border-radius: 10px" :autoResize="true" rows="3" v-model="currentDepense.adminComment"></Textarea>
        <label for="commentaireAdmin">Commentaire gestionnaire</label>
      </div>
      <template #footer>
        <Button label="Fermer" icon="pi pi-times" class="p-button-danger" @click="dialogCommentaires = false; currentDepense = null" autofocus />
        <Button label="Sauvegarder" icon="pi pi-check" @click="saveCommentaire" autofocus />
      </template>
    </Dialog>
    <DialogIkm :showCalculatedDistance="true" @close="dialogIkm = false; currentIkm = null" v-if="dialogIkm" :dialog="dialogIkm" :ikm="currentIkm"></DialogIkm>
  </div>
</template>

<script>
import {getEuroFormat} from "@/utils/Money";
import {getDateFormatted} from "@/utils/Date";
import {capitalizeWord, getOptionLabel} from "@/utils/Misc";
import DialogIkm from "@/components/NDF/DialogIkm";
import {mapState} from "vuex";
import { hasAccess } from "@/utils/Misc";
import NameHeader from "@/components/NameHeader";

export default {
  name: "DialogNDFAdmin",
  components: {NameHeader, DialogIkm},
  emits: ["close", "reload"],
  props: ['dialog', 'ndf'],
  data () {
    return {
      expandedKeys: {},
      hasAccess: hasAccess,
      capitalizeWord: capitalizeWord,
      getEuroFormat: getEuroFormat,
      getDateFormated: getDateFormatted,
      showDialogLocal: this.dialog,
      date: new Date(),
      dialogReview: false,
      dialogCommentaires: false,
      dialogRefusDepense: false,
      currentDepense: null,
      currentIkm: null,
      motifRefusDepense: '',
      dialogRefusNDF: false,
      dialogRefusIkm: false,
      dialogIkm: false,
      motifRefusNDF: '',
      refacturable: false,
      depenses: [],
      ikm: [],
      libelle: '',
      natures: [],
      imgSrc: null,
      headerLabel: ' ', // to prevent the close button of the dialog to be on left,
      fileId: null,

      SUBMIT_TYPE: {
        SAVE: 'SAVE',
        VALIDATE: 'VALIDATE'
      }
    }
  },
  watch: {
    dialog (val) {
      this.showDialogLocal = val
    }
  },
  computed: {
    ...mapState({
      settingsNdf: state => state.misc.settings.NDF,
      constants: state => state.constants
    }),
    totalhtComputed () {
      let somme = 0
      this.depenses.forEach((data) => {
        somme = (somme * 100 + data.data.amountexvat * 100) / 100
      })
      return somme
    },
    totaltaxesComputed () {
      let somme = 0
      this.depenses.forEach((data) => {
        somme = (somme * 100 + data.data.vatamount * 100) / 100
      })
      return somme
    },
    totalttcComputed () {
      return (this.totalhtComputed * 100 + this.totaltaxesComputed * 100) / 100
    },
    totalpriceComputed () {
      let somme = 0
      this.ikm.forEach((ikm) => {
        somme = (somme * 100 + (ikm.price * 100)) / 100
      })
      return somme
    }
  },
  mounted() {
    if (this.ndf) {
      this.date = this.ndf.date
      this.libelle = getOptionLabel(this.constants.data.ndfTypeOptions, this.ndf.type)
      this.refacturable = this.ndf.mission != null
      if (this.ndf.depenses.length !== 0) {
        if (this.ndf.type === this.constants.NDF_TYPE.SOCIAL_MEASURES) {
          this.headerLabel = 'Dispositifs sociaux'
          this.natures = [
            {
              label: 'Dispositifs sociaux',
              items: this.settingsNdf.categoriesDs.filter(cds => cds.enabled)
            }
          ]
        } else {
          this.headerLabel = 'Note de frais'
          this.natures = [
            {
              label: 'Notes de frais',
              items: this.settingsNdf.categoriesNdf.filter(cndf => cndf.enabled)
            }
          ]
        }
        this.ndf.depenses.forEach((depense, key) => {
          this.expandedKeys[key] = true
          const data = { amount: depense.ttc, amountexvat: depense.ht, vatamount: depense.tva, date: depense.date }
          this.depenses.push({ id: depense.id, 'key': key.toString(), data: data, nature: depense.nature, children: [], adminComment: depense.adminComment, independentComment: depense.independentComment, 'justificatif': depense.justificatif, editable: true })
          depense.item.forEach((i, index) => {
            this.depenses[key].editable = false
            this.depenses[key].children.push({ key: key + '-' + index, data: { nature: i.nom, amount: i.ttc, amountexvat: i.ht, vatamount: i.tva } })
          })
        })
      } else if (this.ndf && this.ndf.ikm.length !== 0) {
        this.ikm = this.ndf.ikm
      }
    }
  },
  methods: {
    async downloadFile () {
      const file = await this.$store.dispatch('file/downloadFile', {id: this.fileId })
      const blob = new Blob([new Uint8Array(file.data.data)], { type: file.mimetype })
      const link = document.createElement('a')
      link.download = file.filename
      const url = URL.createObjectURL(blob)
      link.href = url
      link.click()
      URL.revokeObjectURL(url)
    },
    clickCommentairesDepense (depense) {
      this.currentDepense = depense
      this.dialogCommentaires = true
    },
    onUploadJustificatif (file, depense) {
      depense.newJustificatif = file.files[0]
      depense.justificatif = depense.newJustificatif
      this.reviewDepense(depense.newJustificatif)
    },
    async refuserNDF () {
      this.$store.state.misc.loading = true
      const ndf = { id: this.ndf.id, motifRefus: this.motifRefusNDF, independentStatus: this.constants.NDF_INDEPENDENT_STATUS.DRAFT }
      await this.$store.dispatch('ndf/updateNDF', ndf)
      this.$toast.add({severity:'success', summary:'Succès', detail:'Note de frais refusée', life: 3000});
      this.dialogRefusNDF = false
      this.motifRefusNDF = ''
      this.$emit('reload')
      this.$emit('close')
      this.$store.state.misc.loading = false
    },
    async saveCommentaire () {
      this.$store.state.misc.loading = true
      const data = {
        id: this.currentDepense.id,
        adminComment: this.currentDepense.adminComment
      }
      await this.$store.dispatch('depense/updateDepense', data)
      this.$toast.add({severity:'success', summary:'Succès', detail:'Commentaire enregistré', life: 3000});
      this.dialogCommentaires = false
      this.currentDepense = null
      this.$store.state.misc.loading = false
    },
    async preConfirmSave (submitType, callback) {
      if (this.ndf.independentStatus === this.constants.NDF_INDEPENDENT_STATUS.DRAFT) {
        this.$confirm.require({
          header: submitType === this.SUBMIT_TYPE.VALIDATE ? 'Validation NDF' : 'Sauvegarde NDF',
          message: `Êtes-vous sûr de ${submitType === this.SUBMIT_TYPE.VALIDATE ? 'valider' : 'modifer'} la note de frais ? Le porté ne l'a pas encore validée`,
          acceptLabel: 'Oui',
          rejectLabel: 'Non',
          accept: async () => {
            callback()
            this.$confirm.close()
          },
          reject: () => {
          }
        })
      } else {
        callback()
      }
    },
    async saveNDF () {
      this.$store.state.misc.loading = true
      const data = {
        id: this.ndf.id,
        date: this.date,
        depenses: this.ndf.depenses,
        ikm: this.ikm.map((ikm) => {
          return {
            id: ikm.id,
            price: ikm.price * 100
          }
        })
      }

      let index = 0
      for (const dep of this.depenses) {
        data.depenses[index].ttc = Math.round(dep.data.amount * 100)
        data.depenses[index].tva = Math.round(dep.data.vatamount * 100)
        data.depenses[index].ht = Math.round(dep.data.amountexvat * 100)
        data.depenses[index].nature = dep.nature
        data.depenses[index].date = dep.data.date
        data.depenses[index].adminComment = dep.data.adminComment // todo: hotfix -- not permanent fix
        dep.children.forEach((sousdep, sousindex) => {
          data.depenses[index].item[sousindex].ttc = Math.round(sousdep.data.amount * 100)
          data.depenses[index].item[sousindex].tva = Math.round(sousdep.data.vatamount * 100)
          data.depenses[index].item[sousindex].ht = Math.round(sousdep.data.amountexvat * 100)
        })
        if (dep.newJustificatif) {
          const f = dep.newJustificatif
          const form = new FormData()
          form.append('filename', f.name)
          form.append('file', f, f.name)
          const uploadedFile = await this.$store.dispatch('file/uploadFile', form)
          data.depenses[index]['justificatif'] = uploadedFile.id
        }
        index++
      }

      await this.$store.dispatch('ndf/updateNDF', data)

      this.$toast.add({severity:'success', summary:'Succès', detail:'Note de frais enregistrée', life: 3000});
      this.dialogReview = false
      this.$emit('reload')
      this.$emit('close')
      this.$store.state.misc.loading = false
    },
    async validateNDF () {
      this.$store.state.misc.loading = true
      const data = {
        id: this.ndf.id,
        adminStatus: 'refund',
        independentStatus: this.constants.NDF_INDEPENDENT_STATUS.VALIDATED,
        date: this.date,
        depenses: this.ndf.depenses,
        dateValidation: new Date(),
        ikm: this.ikm.map((ikm) => {
          return {
            id: ikm.id,
            price: ikm.price * 100
          }
        })
      }
      let index = 0
      for (const dep of this.depenses) {
        data.depenses[index].ttc = Math.round(dep.data.amount * 100)
        data.depenses[index].tva = Math.round(dep.data.vatamount * 100)
        data.depenses[index].ht = Math.round(dep.data.amountexvat * 100)
        data.depenses[index].nature = dep.nature
        data.depenses[index].date = dep.data.date
        data.depenses[index].adminComment = dep.data.adminComment // todo: hotfix -- not permanent fix
        dep.children.forEach((sousdep, sousindex) => {
          data.depenses[index].item[sousindex].ttc = Math.round(sousdep.data.amount * 100)
          data.depenses[index].item[sousindex].tva = Math.round(sousdep.data.vatamount * 100)
          data.depenses[index].item[sousindex].ht = Math.round(sousdep.data.amountexvat * 100)
        })
        if (dep.newJustificatif) {
          const f = dep.newJustificatif
          const form = new FormData()
          form.append('filename', f.name)
          form.append('file', f, f.name)
          const uploadedFile = await this.$store.dispatch('file/uploadFile', form)
          data.depenses[index]['justificatif'] = uploadedFile.id
        }
        index++
      }
      await this.$store.dispatch('ndf/updateNDF', data)

      if (this.refacturable) {
        await this.$store.dispatch('service/createServiceForNdf', data.id)
      }

      this.$toast.add({severity:'success', summary:'Succès', detail:'Note de frais validée', life: 3000});
      this.dialogReview = false
      this.$emit('reload')
      this.$emit('close')
      this.$store.state.misc.loading = false
    },
    updateSomme (attribute, key, event) {
      if (key.indexOf('-') !== -1) {
        const parent = this.depenses[key.split('-')[0]]
        parent.children[key.split('-')[1]].data[attribute] = event.value
        let somme = 0
        parent.children.forEach((child) => {
          somme = (somme * 100 + child.data[attribute] * 100) / 100
        })
        parent.data[attribute] = somme
      } else {
        this.depenses[key].data[attribute] = event.value
      }
      this.updateTTCs()
    },
    updateTTCs () {
      this.depenses.forEach((parent) => {
        if (parent.children.length) {
          parent.children.forEach((child) => {
            child.data['amount'] = (child.data['amountexvat'] * 100 + child.data['vatamount'] * 100) / 100
          })
        }
        parent.data['amount'] = (parent.data['amountexvat'] * 100 + parent.data['vatamount'] * 100) / 100
      })
    },
    async reviewDepense (file) {
      this.$store.state.misc.loading = true
      this.fileId = null
      let transformToPdf = true
      if (typeof file === 'string') {
        const result = await this.$store.dispatch('file/downloadFile', {id: file})
        this.fileId = file
        if (result.mimetype.includes('image')) transformToPdf = false
        file = new Blob([new Uint8Array(result.data.data)])
      }
      const reader = new FileReader()
      reader.onload = () => {
        this.imgSrc = reader.result
        if (transformToPdf) {
          this.imgSrc = this.imgSrc.replace('application/octet-stream', 'application/pdf')
        }
        this.dialogReview = true
        this.$store.state.misc.loading = false
      }
      reader.readAsDataURL(file)

    },
    async removeDepense (value) {
      this.$confirm.require({
        header: 'Supprimer dépense',
        message: 'Êtes-vous sûr de vouloir supprimer cette dépense de la note de frais ?',
        acceptLabel: 'Oui',
        rejectLabel: 'Non',
        accept: async () => {
          this.$store.state.misc.loading = true
          this.depenses = this.depenses.filter(data => data !== value.node)
          const dep = this.ndf.depenses.find(d => d.id === value.node.id)
          // todo: regroup in one request to prevent issues -- normally no one should pop-up due to await but ...
          await this.$store.dispatch('depense/unassignDepense', dep)
          await this.$store.dispatch('depense/deleteDepense', dep)
          this.$toast.add({severity:'success', summary:'Succès', detail:'Dépense supprimée', life: 3000});
          if (this.depenses.length === 0) {
            this.$emit('close')
          }
          this.$emit('reload')
          this.$store.state.misc.loading = false
          this.$confirm.close()
        },
        reject: () => {
          this.$confirm.close()
        }
      })
      //
      // const value = this.currentDepense
      // this.$store.state.misc.loading = true
      // this.depenses = this.depenses.filter(data => data !== value.node)
      // const dep = this.ndf.depenses.find(d => d.id === value.node.id)
      // dep.motifRefus = this.motifRefusDepense
      // await this.$store.dispatch('depense/unassignDepense', dep)
      // if (this.depenses.length === 0) {
      //   this.$emit('close')
      // }
      // this.$toast.add({severity:'success', summary:'Succès', detail:'Dépense refusée', life: 3000});
      // this.$emit('reload')
      // this.$store.state.misc.loading = false
      // this.currentDepense = null
      // this.dialogRefusDepense = false
      // this.motifRefusDepense = ''
    },
    async removeIkm () {
      const value = this.currentIkm
      this.$store.state.misc.loading = true
      this.ikm = this.ikm.filter(data => data !== value.data)
      const ikm = this.ndf.ikm.find(i => i.id === value.data.id)
      ikm.motifRefus = this.motifRefusDepense
      await this.$store.dispatch('ikm/unassignIkm', ikm)
      if (this.ikm.length === 0) {
        this.$emit('close')
      }
      this.$toast.add({severity:'success', summary:'Succès', detail:'IKM refusée', life: 3000});
      this.$emit('reload')
      this.$store.state.misc.loading = false
      this.currentIkm = null
      this.dialogRefusIkm = false
      this.motifRefusDepense = ''
    },
    async reviewIkm (value) {
      this.currentIkm = value.data
      this.$store.state.misc.loading = true
      await this.$store.dispatch('independent/getOneIndependent', this.currentIkm.independent)
      this.$store.state.misc.loading = false
      this.dialogIkm = true
    },
    getocr (file) {
      const result = JSON.parse(JSON.stringify(this.jsonTest))
      const data = { amount: result.data.amount / 100, amountexvat: result.data.amountexvat / 100, vatamount: result.data.vatamount / 100, date: getDateFormatted(new Date(result.data.date)) }
      const key = this.depenses.length
      this.depenses.push({ 'key': key, data: data, nature: '', children: [], 'justificatif': file.files[0], editable: true })

      result.data.vatitems.forEach((vat, index) => {
        this.depenses[key].editable = false
        this.depenses[key].children.push({ key: key + '-' + index, data: { nature: 'TVA ' + (index + 1), amount: vat.amount_incl_vat / 100, amountexvat: vat.amount_excl_vat / 100, vatamount: vat.amount / 100 } })
      })

      this.reviewDepense(file.files[0])
    }
  }
}
</script>

<style>
.dialog-content-loading {
  background-color: transparent !important;
}
</style>

<style lang="scss" scoped>
::v-deep(.p-dialog) {
  box-shadow: none !important;
}
</style>
