<template>
  <div class="flex flex-grow-1 justify-content-between">
    <div class="flex gap-4">
      <Button v-if="backwardButton" class="p-button-outlined p-button-plain p-button-rounded" icon="fa fa-arrow-left" @click="redirectToPreviousRouteNode()"/>
      <Avatar v-if="showAvatar" size="xlarge" :image="require('@/assets/images/avatars/avataaars.png')" shape="circle" class="mr-2 mb-1 lg:mb-0"/>
      <!--    text-primary-->
      <div class="flex flex-column gap-2">
        <div class="text-3xl font-normal">
          {{ starterTitle ? 'Bonjour, ' : '' }} <span class="text-4xl" style="font-weight: 500">{{ independent?.last_name?.toUpperCase() }} {{ independent?.first_name }}</span>
        </div>
        <div class="flex flex-column text-sm" v-if="showSubtitle">
          <span>{{ `${independent?.email} `}}</span>
          <span>{{ `${independent?.phone}`}}</span>
          <span>{{ `${independent?.personalNumber ? `P${independent?.personalNumber}` : 'Indéfini'}`}}</span>
        </div>
      </div>
    </div>
    <bloc-cagnotte v-if="showMoneyAccount" :independent="independent"/>
  </div>
</template>

<script>
import {formatPhoneNumber} from "@/utils/Misc";
import BlocCagnotte from "@/components/RH/BlocCagnotte";

export default {
  name: "NameHeader",
  components: {BlocCagnotte},
  props: {
    independent: {
      type: Object,
      required: true
    },
    showSubtitle: {
      type: Boolean,
      default: true
    },
    backwardButton: {
      type: Boolean,
      default: true,
      required: false
    },
    backwardOverrideFunction: {
      type: Function,
      required: false
    },
    showMoneyAccount: {
      type: Boolean,
      default: true
    },
    showAvatar: {
      type: Boolean,
      default: true
    },
    starterTitle: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    formatPhoneNumber,
    redirectToPreviousRouteNode () {
      if (this.backwardOverrideFunction) {
        this.backwardOverrideFunction()
      }
      // todo: might need to define a logic to go backward on one node only -> mission/fiche-mission

      this.$router.push({ name:  this.$route.meta.returnView })
    }
  }
}
</script>

<style scoped>

</style>
