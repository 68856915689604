<template>
  <div class="flex flex-column justify-content-center align-items-center px-4 text-white shadow-4 gap-2"
       style="border-radius: 10px;" :style="{'background-color': color}">
    <div class="gap-2 text-md mt-1">
      {{ title }}
    </div>
    <span class="text-xl mb-3" style="font-weight: 600">{{ getEuroFormat(value) }}</span>
  </div>
</template>

<script>
import { getEuroFormat } from '@/utils/Money'

export default {
  name: "Cagnotte",
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      required: true
    }
  },
  components: {},
  data () {
    return {}
  },
  methods: {
    getEuroFormat (value) {
      return getEuroFormat(value, true)
    }
  }
}

</script>
